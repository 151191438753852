import axios from "../utils/axios";

export const send = async ({
    email,
    mobileNo,
    facebookAccount,
    preferredTimeToCall,
}) => {
    try {
        await axios.post("/comm/send-inquiry-email", {
            email: email,
            facebookAccount: facebookAccount,
            mobileNo: mobileNo,
            preferredTimeToCall: preferredTimeToCall,
        });
    } catch (error) {
        console.log(error);
        throw error;
    }
};
