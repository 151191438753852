import React, { useContext, useEffect, useRef } from "react";
import * as Yup from "yup";
import { Box, Loader, Modal } from "../../components";
import { Form, FormField, SubmitButton } from "../../components/Form";
import { StateContext } from "../../context/StateContext";
import { Banner } from "../../components";
import banner from "../../assets/contact-us/banner.jpg";
import { send } from "../../apis/comm";

export default function ContactUs(props) {
    const { state, setState } = useContext(StateContext);

    const scrollRef = useRef(null);

    const validationSchema = Yup.object().shape({
        email: Yup.string().email().required().label("Email"),
        mobileNo: Yup.string().required().min(11).max(12).label("Mobile No."),
        facebookAccount: Yup.string().required().label("Facebook Account"),
        preferredTimeToCall: Yup.string(),
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Banner
                image={banner}
                title="Contact Us"
                description="We are delighted to connect with you."
                backgroundPosition="center center"
            />

            <div>
                <Form
                    initialValues={{
                        email: "",
                        mobileNo: "",
                        facebookAccount: "",
                        preferredTimeToCall: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (data) => {
                        try {
                            setState({ ...state, loading: true });
                            await send(data);
                            setState({
                                ...state,
                                success: true,
                                loading: false,
                            });
                        } catch (error) {
                            console.error(error);
                            setState({ ...state, loading: false, error: true });
                        }
                    }}
                >
                    <div ref={scrollRef}></div>
                    {state.loading ? (
                        <Loader />
                    ) : (
                        <>
                            {(state.success || state.error) && <Modal />}
                            <div className="text-left mt-8 rounded-sm lg:mt-16 lg:mb-20">
                                <Box className="shadow-4xl my-10 py-10 lg:px-10 lg:mx-36">
                                    <div className="px-3">
                                        <h1 className="text-blue font-bold pb-5 text-2xl xl:text-4xl md:text-3.25xl font-medium">
                                            Get In Touch
                                        </h1>
                                        <p>
                                            Let's get this conversation started.
                                            Send us a message and let us know
                                            how we can help.
                                        </p>
                                    </div>

                                    <div className="flex flex-wrap">
                                        <div className="w-full p-3 sm:w-1/2">
                                            <FormField
                                                placeholder="Email Address"
                                                customStyle="w-full"
                                                name="email"
                                                key="email"
                                            />
                                        </div>

                                        <div className="w-full p-3 sm:w-1/2">
                                            <FormField
                                                placeholder="Mobile No."
                                                customStyle="w-full"
                                                name="mobileNo"
                                                key="mobileNo"
                                            />
                                        </div>

                                        <div className="w-full p-3 sm:w-1/2">
                                            <FormField
                                                placeholder="Facebook Account"
                                                customStyle="w-full"
                                                name="facebookAccount"
                                                key="facebookAccount"
                                            />
                                        </div>
                                        <div className="w-full p-3 sm:w-1/2">
                                            <FormField
                                                placeholder="Prefer Time to Call"
                                                customStyle="w-full"
                                                name="preferredTimeToCall"
                                                key="preferredTimeToCall"
                                            />
                                        </div>
                                    </div>

                                    <div className="p-3 flex gap-4 w-full">
                                        <SubmitButton
                                            title="Send"
                                            customStyle="mr-16 rounded-lg"
                                        />
                                    </div>
                                </Box>
                            </div>
                        </>
                    )}
                </Form>
            </div>
        </>
    );
}
