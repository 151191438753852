import React from "react";

import { loanProcessSteps } from "../../data";

function LoanProcess(props) {
    return (
        <div className="py-10 relative lg:py-24">
            <h1 className="text-blue text-2xl xl:text-4xl md:text-3.25xl font-medium mx-5">
                Get Your Loan Approved In 3 Easy Ways
            </h1>
            {/* <div className="flex justify-center w-full mt-5">
                <p className="text-center text-black2 mx-5 text-sm 2xl:text-lg xl:w-6/12 md:text-base">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Quis ipsum suspendisse ultrices gravida.
                </p>
            </div> */}
            <div className="flex flex-wrap mt-10 px-5 lg:px-12 xl:px-36 md:mt-16 justify-around">
                {loanProcessSteps.map(({ icon, step, process }) => {
                    return (
                        <div
                            key={step}
                            className="w-10/12 lg:w-1/3 md:w-1/2 md:my-5 lg:mx-0 md:mx-2"
                        >
                            <div className="bg-background1 w-full space-y-12 flex flex-col justify-center py-10 my-5 filter shadow-lg rounded-lg lg:h-96 lg:w-11/12 lg:my:0 lg:py-0">
                                <img
                                    className="self-center"
                                    src={icon}
                                    alt="Icon"
                                />
                                <div className="flex mx-auto break-words px-10">
                                    <h1 className="text-4xl text-blue font-medium">
                                        {step}
                                    </h1>
                                    <h1 className="pl-5 text-center text-grey text-xl">
                                        {process}
                                    </h1>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default LoanProcess;
