import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { HomePage, AboutUsPage, OurLeadersPage, ContactUsPage } from "./pages";
import { Footer, NavBar } from "./components";

export default function Routes(props) {
    return (
        <>
            <Router basename="/">
                <NavBar />
                <Switch>
                    <Route path="/about-us" component={AboutUsPage} exact />
                    <Route
                        path="/our-leaders"
                        component={OurLeadersPage}
                        exact
                    />
                    <Route path="/contact-us" component={ContactUsPage} exact />
                    <Route path="/" component={HomePage} />
                </Switch>
                <Footer />
            </Router>
        </>
    );
}
