import React from "react";
import { useFormikContext } from "formik";

import TextInput from "../TextInput";
import ErrorMessage from "./ErrorMessage";

export default function AppFormField({ name, ...otherProps }) {
    const { setFieldTouched, setFieldValue, errors, touched, values } =
        useFormikContext();

    return (
        <>
            <TextInput
                onBlur={() => setFieldTouched(name)}
                onChange={(e) => setFieldValue(name, e.target.value)}
                value={values[name]}
                errors={touched[name] ? errors[name] : null}
                {...otherProps}
            />
            <ErrorMessage
                error={errors[name]}
                visible={touched[name]}
                customStyle="text-xs text-red relative pt-2 sm:text-2xs"
            />
        </>
    );
}
