import React from "react";

export default function Banner({
    image,
    title,
    description,
    backgroundPosition = "center center",
}) {
    return (
        <div
            className="w-screen h-72"
            style={{
                backgroundImage: `url(${image})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: backgroundPosition,
            }}
        >
            <div className="flex h-full w-full justify-start items-end">
                <div className="mx-5 mb-10 lg:ml-36">
                    <h1 className="text-left text-white text-2.5xl xl:text-3.5xl md:text-3.25xl font-medium">
                        {title}
                    </h1>
                    <p className="text-left text-white text-sm 2xl:text-lg xl:w-10/12 md:text-base">
                        {description}
                    </p>
                </div>
            </div>
        </div>
    );
}
