import React from "react";

import { contactDetails, socials } from "../../data";

export default function Footer(props) {
    return (
        <>
            <div className="bg-blue">
                <div className="flex flex-wrap text-white space-y-10 py-28 lg:space-y-0 text-left">
                    {contactDetails.map(({ mode, info, image }) => {
                        return (
                            <div
                                key={mode}
                                className="w-full text-center lg:text-left lg:items-start lg:flex lg:space-y-0 lg:justify-center lg:w-1/3"
                            >
                                <div className="grid justify-items-center lg:justify-items-start lg:flex">
                                    <img
                                        className="z-100 h-8 lg:h-16"
                                        src={image}
                                        alt={mode}
                                    />
                                </div>

                                <div className="space-y-3 lg:space-y-2 lg:ml-5">
                                    <p className="font-medium text-2xl">
                                        {mode}
                                    </p>
                                    <div className="space-y-2">
                                        <p>{info[0]}</p>
                                        <p>{info[1]}</p>
                                        <p>{info[2]}</p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="flex justify-center space-x-5 py-5 mb-5">
                    {socials.map(({ title, icon, link }) => {
                        return (
                            <div key={title} className="text-yellow">
                                <a
                                    className="hover:text-white"
                                    href={link}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {icon}
                                </a>
                            </div>
                        );
                    })}
                </div>
                <div className="py-5 border-t-2 border-yellow">
                    <p className="text-yellow text-base">
                        @ 2022 · All rights reserved.
                    </p>
                </div>
            </div>
        </>
    );
}
