import React, { useContext, useEffect } from "react";
import { useFormikContext } from "formik";

import Button from "../Button";
import { StateContext } from "../../context/StateContext";

export default function SubmitButton({
    title,
    customStyle,
    color = "bg-blue",
}) {
    const { handleSubmit, handleReset } = useFormikContext();

    const { state } = useContext(StateContext);

    const handleClick = () => {
        handleSubmit();
    };

    useEffect(() => {
        if (state.success) handleReset();
        // eslint-disable-next-line
    }, [state]);

    return (
        <div>
            <Button
                type="submit"
                title={title}
                customStyle={customStyle}
                color={color}
                onClick={handleClick}
                disabled={false}
            />
        </div>
    );
}
