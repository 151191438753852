import step1 from "../../assets/loan-process/step1.png";
import step2 from "../../assets/loan-process/step2.png";
import step3 from "../../assets/loan-process/step3.png";

const loanProcessSteps = [
    {
        icon: step1,
        step: 1,
        process:
            "Fill out a loan application form and submit your requirements.",
    },
    {
        icon: step2,
        step: 2,
        process:
            "Our credit officer will process your application and notify you upon its approval.",
    },
    {
        icon: step3,
        step: 3,
        process:
            "Your loan proceeds will be credited to your designated bank account or via check.",
    },
];

export default loanProcessSteps;
