import { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link, NavLink } from "react-router-dom";

import logo from "../../assets/aces-logo.png";

const navigations = [
    {
        name: "Home",
        href: "/",
        hash: "",
    },
    {
        name: "About Us",
        href: "/about-us",
        hash: "",
    },
    {
        name: "Our Leaders",
        href: "/our-leaders",
        hash: "",
    },
    {
        name: "Contact Us",
        href: "/contact-us",
        hash: "",
    },
];

export default function NavBar() {
    const [bgColor, setBgColor] = useState("bg-transparent");

    const changeBackground = () => {
        if (window.scrollY >= 20) {
            setBgColor("bg-yellow64");
        } else {
            setBgColor("bg-transparent");
        }
    };

    useEffect(() => {
        changeBackground();
        window.addEventListener("scroll", changeBackground);
    });

    return (
        <Popover className={`sticky top-0 z-50`}>
            <div
                className={`max-w-full w-full px-4 2xl:px-28 xl:pr-16 lg:px-5 md:px-auto sm:px-6 sm:mx-0 absolute ${bgColor} z-50`}
                onScroll={() => console.log("scrolling")}
            >
                <div className="flex w-full justify-between lg:justify-around items-center py-6 md:justify-start md:space-x-5">
                    <div className="-mr-2 -my-2 w-full xl:hidden flex justify-between items-end">
                        <Popover.Button className="text-white rounded-md  items-end justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-0">
                            <span className="sr-only">Open menu</span>
                            <MenuIcon
                                className="h-9 w-10 mb-2 md:ml-3 lg:ml-5"
                                aria-hidden="true"
                            />
                        </Popover.Button>
                    </div>
                    <div className="flex justify-self-end">
                        <Link
                            to="/"
                            className="font-medium text-white text-base md:text-3xl font-bold sm:text-lg hover:text-blue focus:text-blue"
                        >
                            <img
                                className="mr-7 object-cover w-12 h-12 xl:h-14 xl:w-9/12 lg:ml-0"
                                src={logo}
                                alt="Aces Logo"
                            />
                        </Link>
                    </div>

                    <div className="items-center flex flex-1 -mr-3 lg:mr-36 lg:w-0 md:mr-5 ">
                        <Popover.Group
                            as="nav"
                            className="hidden xl:flex space-x-10 items-center mr-10"
                        >
                            {navigations.map(({ name, href, hash }) => (
                                <NavLink
                                    to={{
                                        pathname: href,
                                        hash: hash,
                                    }}
                                    key={name}
                                    className="relative group text-lg w-max font-medium text-white hover:text-blue focus:text-blue"
                                >
                                    {name}
                                    <div className="transition duration-500 ease-in-out absolute h-0.5 mt-1 w-0 transform group-focus:text-blue w-5/6" />
                                </NavLink>
                            ))}
                        </Popover.Group>
                        {/* <div className="mr-5 hidden lg:flex">
                            <LinkButton
                                title="Contact Us"
                                href="/become-a-host"
                                customStyle="shadow-none border-2 text-purple"
                                color="bg-background1"
                            />
                        </div> */}
                        {/* <div>
                            <LinkButton
                                title="Become a Reseller"
                                href="/become-a-host"
                                customStyle={buttonColor}
                            />
                        </div> */}
                    </div>
                </div>
            </div>

            <Transition
                as={Fragment}
                enter="duration-200 ease-out"
                enterFrom="opacity-0 scale-0"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-0"
            >
                <Popover.Panel
                    focus
                    className="absolute top-0 inset-x-0 p-2 transition transform origin-top-left xl:hidden z-50"
                >
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 divide-y-2 divide-white bg-blue text-white">
                        <div className="pt-5 pb-6 px-5">
                            <div className="flex items-center justify-start">
                                <div className="-mr-2">
                                    <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-white hover:text-white">
                                        <span className="sr-only">
                                            Close menu
                                        </span>
                                        <XIcon
                                            className="h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    </Popover.Button>
                                </div>
                            </div>
                            <div className="mt-6">
                                <nav className="grid gap-y-8">
                                    {navigations.map(({ name, href, hash }) => (
                                        <Popover.Button key={name}>
                                            <NavLink
                                                to={{
                                                    pathname: href,
                                                    hash: hash,
                                                }}
                                                className="-m-3 p-3 flex justify-center items-center rounded-md "
                                                aria-hidden="true"
                                            >
                                                <span className="ml-3 text-base font-medium text-gray-900 group focus:text-white">
                                                    {name}
                                                    <div className="transition duration-500 ease-in-out absolute h-0.5 mt-1 w-0 transform group-focus:bg-background2 w-5/6" />
                                                </span>
                                            </NavLink>
                                        </Popover.Button>
                                    ))}
                                    {/* <div className="ml-3 flex lg:hidden justify-center">
                                        <LinkButton
                                            title="Contact Us"
                                            href="/become-a-host"
                                            customStyle="shadow-none border-2 text-purple"
                                            color="bg-white"
                                        />
                                    </div> */}
                                </nav>
                            </div>
                        </div>
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    );
}
