import review1 from "../../assets/reviews/review1.jpg";
import review2 from "../../assets/reviews/review2.jpg";
import review3 from "../../assets/reviews/review3.jpg";
import review4 from "../../assets/reviews/review4.jpg";
import review5 from "../../assets/reviews/review5.jpg";
import review6 from "../../assets/reviews/review6.jpg";

const reviews = [
    {
        id: "1",
        name: "Robert Abenir",
        title: "Client",
        image: review1,
        review: "Thank you sa Aces seaman's loan,mabilis yung approval at malaking tulong po kayo sa aming mga seaman na nangangailangan. God bless...",
    },
    {
        id: "2",
        name: "Marie Rebita",
        title: "Client",
        image: review2,
        review: "To this Aces Credit Experts and Solutions. I would like to thank you from the bottom of my heart for allowing me to kind of pandemic situation of ours.To the staff thanks for their kindness and welcoming us. Thank u so much for everything that u do for us.",
    },
    {
        id: "3",
        name: "Gerald Cinco",
        title: "Client",
        image: review3,
        review: "Fast process, hassle free, office accessible",
    },
    {
        id: "4",
        name: "Onin Perez",
        title: "Client",
        image: review4,
        review: "Second time na po ako ng loan. mababait mga staff hindi masungit. madaling kausapin. maganda na maganda pa ugali",
    },
    {
        id: "5",
        name: "Raymond Calang",
        title: "Client",
        image: review5,
        review: "Good building and nice processing of loan,very fast process 👌",
    },
    {
        id: "6",
        name: "Roy Capilitan Bersabal",
        title: "Client",
        image: review6,
        review: "GOOD SERVICES, LOW INTEREST, AND FAST APPROVAL... ",
    },
];

export default reviews;
