import React, { useContext, Fragment } from "react";

import { StateContext } from "../../context/StateContext";

import loader from "../../assets/aces-loader.gif";

export default function Loader({ message }) {
    const { state } = useContext(StateContext);

    return (
        <>
            {state.loading && (
                <div className="m-auto w-screen h-full absolute bg-white z-50">
                    <img
                        className="z-50 flex-1 object-none w-full h-full scale-25"
                        src={loader}
                        alt="Loading"
                    />
                    <h2 className="text-2.5xl font-bold text-blue">
                        {message}
                    </h2>
                </div>
            )}
        </>
    );
}
