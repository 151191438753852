import React, { useEffect } from "react";

import { Banner } from "../../components";

import { ourLeaders } from "../../data";

import banner from "../../assets/our-leaders/banner.png";

export default function OurLeaders(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Banner
                image={banner}
                title="Our Leaders"
                description="Meet the faces of Aces leading the company to deliver outstanding and excellent service."
                backgroundPosition="right top"
            />
            {ourLeaders.map(({ id, fullName, title, description, image }) => {
                return (
                    <div
                        key={id.toString()}
                        className={`flex flex-wrap justify-around items-center text-left py-10 px-5 lg:py-20 lg:px-12 ${
                            id % 2 === 0 ? `bg-background1` : `bg-white`
                        }`}
                    >
                        {id % 2 === 0 && (
                            <div className="mb-5 lg:mb-0">
                                <img className="" src={image} alt={fullName} />
                            </div>
                        )}

                        <div className="w-full space-y-5 lg:w-1/2 lg:space-y-10">
                            <div>
                                <h3 className="text-blue text-2xl lg:text-3xl font-bold">
                                    {fullName}
                                </h3>
                                <p className="text-lg font-medium mt-2">
                                    {title}
                                </p>
                            </div>
                            <div className="space-y-5">
                                <p>{description[0]}</p>
                                <p>{description[1]}</p>
                                <p>{description[2]}</p>
                                <p>{description[3]}</p>
                                <p>{description[4]}</p>
                            </div>
                        </div>

                        {id % 2 !== 0 && (
                            <div className="mb-5 lg:mb-0">
                                <img className="" src={image} alt={fullName} />
                            </div>
                        )}
                    </div>
                );
            })}
        </>
    );
}
