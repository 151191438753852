import React from "react";

function TextArea({ customStyle, ...otherProps }) {
    return (
        <textarea
            id={otherProps.name}
            name={otherProps.name}
            rows={otherProps.rows}
            className={`mt-1 py-3 px-5 focus:border-blue block border-2 border-none rounded-md sm:text-sm ${customStyle}`}
            placeholder={otherProps.placeholder}
            defaultValue={""}
        />
    );
}

export default TextArea;
