const loanRequirements = [
    {
        id: "contract",
        name: "Contract",
        description: "  Contract of Employment with the agency",
    },
    {
        id: "oec",
        name: "OEC",
        description: "Overseas Employment Certificate validated by the POEA",
    },
    {
        id: "passport",
        name: "Passport",
        description: "Validated Passport",
    },
    {
        id: "seaman's-book",
        name: "Seaman's Book",
        description:
            "Validated Seafarer’s Identification and Record Book (SIRB)",
    },
    {
        id: "allotment-cert",
        name: "Allotment Certificate",
        description: "Official copy of allotment certificate",
    },
];

export default loanRequirements;
