import React, { useEffect } from "react";
import { Banner } from "../../components";

import banner from "../../assets/about-us/banner.png";
import missionVision from "../../assets/about-us/mission-vision.png";
import ourClients from "../../assets/about-us/our-clients.png";

export default function AboutUs(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Banner
                image={banner}
                title="About Us"
                description="Aces Credit Experts & Solutions Inc. is a growing financial institution that delivers excellent service."
                backgroundPosition="center right"
            />
            <div className="py-10 lg:py-28">
                <h1 className="text-blue text-2xl xl:text-4xl md:text-3.25xl font-medium">
                    Our Company
                </h1>
                <div className="w-full px-5 mt-10 space-y-5 lg:px-36">
                    <p className="text-center text-black2 text-sm 2xl:text-lg xl:w-full md:text-base ">
                        Conceptualized in 2017, Aces Credit Experts and
                        Solutions Inc. (“ACES”) was born from a vision and the
                        need to extend credit services to the underserved
                        segments of financial institutions. With the diverse
                        backgrounds of its principals in finance, accounting,
                        sales, marketing and business development, coupled with
                        an eagerness to blaze a trail in the industry, ACES was
                        incorporated on April 27, 2018.
                    </p>
                    <p className="text-center text-black2 text-sm 2xl:text-lg xl:w-full md:text-base ">
                        Despite being a new player in the lending industry, the
                        company already boasts of a healthy portfolio in its
                        first year of operations. The stockholders and Board of
                        Directors are long-time partners in various businesses.
                        Meanwhile, the management team is composed of young
                        individuals accustomed to business with diverse
                        educational backgrounds and work experiences.
                    </p>
                </div>
            </div>
            <div className="flex flex-wrap justify-around py-10 px-5 bg-blue text-white lg:space-x-20 lg:px-20 lg:py-28">
                <div className="text-left space-y-10 lg:pr-36 lg:w-6/12">
                    <h1 className="text-yellow text-2xl xl:text-4xl md:text-3.25xl font-medium">
                        Our Mission and Vision
                    </h1>
                    <p>
                        The main purpose of ACES is to provide the best service
                        to our customers, stakeholders and business partners to
                        help them attain their life goals and more than they
                        deserve.
                    </p>
                    <div className="space-y-5">
                        <div className="space-y-3">
                            <h5 className="text-xl font-bold">Our Mission</h5>
                            <p>
                                Our mission is to provide our clients in the
                                Philippines with various types of loans at
                                competitive rates, while ensuring customer
                                convenience in every step of our process.
                            </p>
                        </div>
                        <div className="space-y-3">
                            <h5 className="text-xl font-bold">Our Vision</h5>
                            <p>
                                Our vision is for every Filipino to have the
                                opportunity of experiencing financial freedom.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="order-first lg:order-last mb-10 lg:mb-0">
                    <img
                        className=""
                        src={missionVision}
                        alt={"Mission Vision"}
                    />
                </div>
            </div>
            <div className="bg-background1 flex flex-wrap">
                <div className="w-full lg:w-1/2">
                    <img
                        className="w-full"
                        src={ourClients}
                        alt={"Our Clients"}
                    />
                </div>
                <div className="w-full bg-background1 py-10 px-5 text-left space-y-5 lg:pt-28 lg:px-20 lg:w-1/2">
                    <h1 className="text-blue pb-5 text-2xl xl:text-4xl md:text-3.25xl font-medium">
                        Our Clients
                    </h1>
                    <p>
                        Just like any financial institution, we select our
                        clients based on their profiles and their capacity to
                        pay. Our top clients are Filipino seafarers. We also
                        cater to select individual clients and small businesses
                        for short-term personal and business loans.
                    </p>
                </div>
            </div>
        </>
    );
}
