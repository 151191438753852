import React from "react";
import { Formik } from "formik";

export default function AppForm({
    initialValues,
    onSubmit,
    validationSchema,
    children,
}) {
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            errors
            isValid
            isInitialValid={false}
            setFieldTouched
            initialStatus={{ disabled: true }}
        >
            {() => <>{children}</>}
        </Formik>
    );
}
