import phone from "../../assets/footer/phone.png";
import email from "../../assets/footer/email.png";
import address from "../../assets/footer/address.png";

const contactDetails = [
    {
        mode: "Email",
        info: ["loan@acescredit.ph"],
        image: email,
    },
    {
        mode: "Phone",
        info: ["09552746241 (Globe)", "09190028512 (Smart)"],
        image: phone,
    },
    {
        mode: "Address",
        info: [
            "11th floor, Ma. Natividad Bldg.,",
            "Kalaw Ave., Ermita, Manila",
            "Mon - Fri 9am - 6pm",
        ],
        image: address,
    },
];

export default contactDetails;
