import React, { useState } from "react";

import Button from "../Button";
import LoanCalculator from "../LoanCalculator";

import coverPhoto from "../../assets/hero/hero-cover.png";
import VideoModal from "../VideoModal";

export default function Hero({ defaultRef }) {
    const [openVideo, setOpenVideo] = useState(false);

    return (
        <>
            <div
                className="bg-local w-screen h-max"
                style={{
                    backgroundImage: `url(${coverPhoto})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "top center",
                }}
            >
                <div ref={defaultRef} className="w-screen h-max">
                    <div className="flex flex-wrap space-y-10 gap-0 h-max xl:h-full items-end">
                        <div className="w-11/12 h-11/12 text-left self-center pl-5 2xl:pl-36 lg:pl-16 lg:mt-24 lg:w-1/2 lg:pt-0 md:w-9/12 md:pl-10 ipadPro:w-10/12">
                            <h1 className="text-white mb-2 text-xl mt-24 font-medium xl:text-4xl md:text-3.25xl sm:text-2.5xl sm:mr-0">
                                Sa ano mang hamon ng buhay, mananatiling
                                kaagapay si Aces sa inyong paglalakbay.
                            </h1>
                            {/* <h5 className="my-5 w-full text-white text-sm 2xl:w-8/12 2xl:text-lg lg:text-base md:text-base sm:my-5 sm:text-lg">
                                For inquiries and applications, you may contact
                                us at: 09173036306 (Globe) 09190028512 (Smart)
                                Mabilis at maaasahang serbisyo.
                            </h5> */}
                            <div className="w-4/5 mt-10 2xl:w-2/5 lg:w-3/5 lg:h-32 lg:w-2/5">
                                <Button
                                    title="Watch Video"
                                    href="/home"
                                    customStyle="transition ease-in-out duration-700 bg-transparent rounded-3xl border-2 border-white hover:bg-blue"
                                    target="_blank"
                                    external
                                    onClick={() => setOpenVideo(true)}
                                />
                            </div>
                        </div>
                        <div className="flex h-full items-center ml-5 w-full xl:w-1/2 lg:ml-0 md:ml-10">
                            <LoanCalculator customStyle="flex w-full content-center mb-10 max-h-full justify-center lg:my-12 lg:max-h-4/12 md:w-10/12" />
                        </div>
                    </div>
                </div>
            </div>
            <VideoModal isOpen={openVideo} setIsOpen={setOpenVideo}>
                <iframe
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-11/12 h-96 lg:w-10/12 lg:h-full"
                    src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FAcesCreditExperts%2Fvideos%2F4289419647761934%2F&show_text=false&width=560&t=0"
                    scrolling="no"
                    frameBorder={0}
                    allowFullScreen={true}
                    allow="autoplay; encrypted-media; picture-in-picture; web-share"
                    title="Aces Video"
                />
            </VideoModal>
        </>
    );
}
