import React from "react";

import { reviews } from "../../data";

export default function Reviews() {
    return (
        <>
            <div id="reviews" className="py-10 lg:py-24">
                <h1 className="text-blue text-2xl xl:text-4xl md:text-3.25xl font-medium">
                    What Clients Say About Us
                </h1>
                <div className="flex justify-center w-full mt-5">
                    {/* <p className="text-center text-black2 text-sm px-5 2xl:text-lg xl:w-6/12 md:text-base">
                        We highly appreciate your comments, suggestions and
                        feedback on us.
                    </p> */}
                </div>
                <div
                    id="reviews"
                    className="w-full overflow-x-scroll pt-0 sm:overflow-hidden xl:pt-0 md:pt-3 sm:pt2"
                >
                    <div className="my-5 flex whitespace-nowarp scroller-xs w-full sm:scroller sm:my-10 ">
                        {reviews.map(({ id, title, name, image, review }) => (
                            <div
                                key={id}
                                className="flex bg-yellow mt-8 rounded-2xl h-max p-5 min-w-full w-full mx-5 overflow-hidden md:p-12 md:min-w-max md:w-max"
                            >
                                <div className="rounded-full h-24 w-24 md:h-32 md:w-32">
                                    <img
                                        className="self-center h-2/3 rounded-full"
                                        src={image}
                                        alt="Step 1"
                                    />
                                </div>
                                <div className="text-left w-80 space-y-5">
                                    <div>
                                        <p className="text-blue text-xl">
                                            {name}
                                        </p>
                                        <p className="text-sm text-black2">
                                            {title}
                                        </p>
                                    </div>
                                    <p>{review}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}
