import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function VideoModal({ children, isOpen, setIsOpen }) {
    // const [isOpen, setIsOpen] = useState(false);

    console.log(isOpen);

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-10 overflow-y-auto bg-black900"
                    onClose={() => setIsOpen(false)}
                    style={{ zIndex: 9999 }}
                    data-keyboard="false"
                >
                    <div className="px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 opacity-50" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="h-max w-max">{children}</div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}
