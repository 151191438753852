import React from "react";

import { loanRequirements } from "../../data";

import coverPhoto from "../../assets/loan-requirements/cover.png";
import checkbox from "../../assets/loan-requirements/checkbox.png";

export default function LoanRequirements({ defaultRef }) {
    return (
        <>
            <div
                ref={defaultRef}
                className="h-full w-full bg-blue overflow-y-hidden"
            >
                <div className="grid grid-cols-1 gap-0 w-full h-11/12 ml-5 2xl:h-full 2xl:ml-32 xl:ml-16 lg:grid-cols-2 lg:ml-12 lg:mt-0 md:ml-10">
                    <div className="w-10/12 h-11/12 text-left self-center lg:mt-24 lg:w-full md:w-9/12">
                        <h1 className="hidden text-yellow mt-10 mb-2 lg:mr-5 text-xl font-medium xl:text-4xl lg:block md:text-3.25xl sm:text-2.5xl sm:mr-0">
                            Seafarer's Loan
                        </h1>
                        <h1 className="hidden text-yellow mb-10 mr-5 text-xl font-medium xl:text-4xl lg:block md:text-3.25xl sm:text-2.5xl sm:mr-0">
                            Requirements
                        </h1>
                        <h1 className="block text-yellow my-10 lg:mr-5 text-xl font-medium xl:text-4xl md:text-3.25xl sm:text-2.5xl sm:mr-0 lg:hidden">
                            Seafarer's Loan Requirements
                        </h1>
                        <div className="space-y-8 mb-16">
                            {loanRequirements.map(
                                ({ id, name, description }) => {
                                    return (
                                        <div
                                            key={id}
                                            className="flex items-start"
                                        >
                                            <img
                                                className="object-contain w-8 lg:w-12"
                                                src={checkbox}
                                                alt="Checkbox"
                                            />
                                            <div className="ml-3">
                                                <h5 className="w-full text-yellow font-medium text-sm 2xl:w-full 2xl:text-2.5xl lg:text-2xl md:text-xl sm:text-lg">
                                                    {name}
                                                </h5>
                                                <p className="w-full text-white mt-3 text-sm 2xl:text-xl lg:text-lg md:text-lg sm:text-base">
                                                    {description}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    </div>

                    <div
                        className="bg-local hidden w-full h-full md:block"
                        style={{
                            backgroundImage: `url(${coverPhoto})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            justifySelf: "flex-end",
                        }}
                    >
                        <div className="h-full w-full"></div>
                    </div>
                </div>
            </div>
        </>
    );
}
