import React from "react";

import { brandGuarantee } from "../../data";

function OurBrandGuarantee(props) {
    return (
        <div className="py-10 relative lg:py-24 bg-background1">
            <h1 className="text-blue text-2xl xl:text-4xl md:text-3.25xl font-medium mx-5">
                The service we provide
            </h1>
            {/* <h4 className="mt-2 text-black2 text-xl xl:text-2.5xl md:text-2xl font-medium mx-5">
                The service we provide
            </h4> */}

            <div className="flex flex-wrap mt-10 px-5 lg:px-12 xl:px-36 md:mt-16 justify-around">
                {brandGuarantee.map(({ id, title, icon, description }) => {
                    return (
                        <div
                            key={id}
                            className="w-10/12 lg:w-1/3 md:w-1/2 md:my-5 lg:mx-0"
                        >
                            <div className="relative w-full flex flex-col justify-items-end py-10 filter lg:w-11/12 lg:my-0 lg:py-0">
                                <img
                                    className="self-center transform scale-75 lg:scale-100 lg:mb-8"
                                    src={icon}
                                    alt="Icon"
                                />
                                <div className="break-words text-center">
                                    <h1 className="text-2xl text-blue font-medium my-3 lg:my-0 lg:text-2.5xl">
                                        {title}
                                    </h1>
                                    <h1 className="text-black2 text-xl mt-1">
                                        {description[0]}
                                    </h1>
                                    <h1 className="text-black2 text-xl mt-1">
                                        {description[1]}
                                    </h1>
                                    <h1 className="text-black2 text-xl mt-1">
                                        {description[2]}
                                    </h1>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default OurBrandGuarantee;
