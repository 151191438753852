import React, { useEffect } from "react";

import {
    Hero,
    Reviews,
    LoanProcess,
    LoanRequirements,
    OurBrandGuarantee,
} from "../../components";

// import MessengerChat from "../../components/MessengerChat";

export default function HomePage(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Hero />
            <OurBrandGuarantee />
            <LoanProcess />
            <LoanRequirements />
            <Reviews />
        </>
    );
}
