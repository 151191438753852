import React from "react";

export default function Button({
    title,
    color = "bg-blue",
    customStyle,
    type,
    onClick,
    disabled,
}) {
    return (
        <div>
            {disabled ? (
                <button
                    disabled
                    className={`cursor-not-allowed disabled:opacity-50 w-full flex items-center justify-center px-4 py-3 rounded-md shadow-2xl text-base font-medium text-white ${color} ${customStyle}`}
                    type={type}
                    onClick={onClick}
                >
                    {title}
                </button>
            ) : (
                <button
                    className={`w-full flex items-center justify-center px-4 py-3 rounded-2xl shadow-md text-base font-medium text-white ${color} ${customStyle}`}
                    type={type}
                    onClick={onClick}
                >
                    {title}
                </button>
            )}
        </div>
    );
}
