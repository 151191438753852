import React from "react";
import { Link } from "react-router-dom";

export default function LinkButton({
    title,
    customStyle,
    href = "/",
    color = "bg-purple",
    target,
    external,
}) {
    return (
        <div>
            {!external ? (
                <button
                    className={`w-full flex items-center justify-center px-4 py-2 rounded-md shadow-2xl text-base font-medium text-white ${color} ${customStyle}`}
                >
                    <Link to={href}>{title}</Link>
                </button>
            ) : (
                <a
                    href={href}
                    target={target}
                    rel="noreferrer"
                    className={`w-full flex items-center justify-center px-4 py-2 rounded-md shadow-2xl text-base font-medium text-white ${color} ${customStyle}`}
                >
                    {title}
                </a>
            )}
        </div>
    );
}
