import React from "react";

export default function TextInput({ customStyle, ...otherProps }) {
    return (
        <input
            className={`mt-1 py-3 px-5 focus:border-blue border-gray block border-2 border-1 rounded-md sm:text-sm ${customStyle}`}
            {...otherProps}
        />
    );
}
