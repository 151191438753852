import { FaFacebookF, FaTiktok } from "react-icons/fa";

const socials = [
    {
        title: "facebook",
        icon: <FaFacebookF size={25} />,
        link: "https://www.facebook.com/AcesCreditExperts",
    },
    {
        title: "tiktok",
        icon: <FaTiktok size={25} />,
        link: "https://tiktok.com/@aces_credit",
    },
];

export default socials;
