import fast from "../../assets/our-brand-guarantee/fast.png";
import convenient from "../../assets/our-brand-guarantee/convenient.png";
import outstandingService from "../../assets/our-brand-guarantee/outstanding-service.png";

const brandGuarantee = [
    {
        id: "fast",
        title: "FAST",
        description: ["One day process and release"],
        icon: fast,
    },
    {
        id: "convenient",
        title: "CONVENIENT",
        description: ["Cash release or Same day deposit"],
        icon: convenient,
    },
    {
        id: "outstanding",
        title: "OUTSTANDING SERVICE",
        description: [
            "* Full attention to detail",
            "* Prompt response ",
            "* Friendly staff",
        ],
        icon: outstandingService,
    },
];

export default brandGuarantee;
