import React, { useState, useEffect } from "react";

import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";

import LinkButton from "../LinkButton";

import { fCurrency } from "../../utils";

import { loanAmountMarks } from "../../data";

const rangeColor = "#002F63";

const termMarks = [
    {
        value: 0,
        label: "0",
    },
    {
        value: 6,
        label: "6",
    },
    {
        value: 12,
        label: "12",
    },
    {
        value: 18,
        label: "18",
    },
    {
        value: 24,
        label: "24",
    },
];

const ValueLabelComponent = (props) => {
    const { children, value, ownerState } = props;

    return (
        <Tooltip
            style={{ zIndex: 0 }}
            className="z-0"
            open
            enterTouchDelay={0}
            leaveDelay={3600000}
            placement="top"
            title={ownerState.name === "loan-amount" ? fCurrency(value) : value}
            arrow
            componentsProps={{
                tooltip: {
                    sx: {
                        fontWeight: "bold",
                        backgroundColor: rangeColor,
                        fontSize: "0.8em",
                        zIndex: 0,
                    },
                },
                arrow: {
                    sx: {
                        color: rangeColor,
                    },
                },
            }}
        >
            {children}
        </Tooltip>
    );
};

const MarkLabelComponent = (props) => {
    const { children } = props;

    return (
        <>
            {children === "0" && (
                <p className="top-7 text-grey absolute font-medium">
                    {children}
                </p>
            )}
            {children === "2,000,000" && (
                <p className="top-7 right-0 text-grey absolute font-medium">
                    {children}
                </p>
            )}
            {children === "24" && (
                <p className="top-7 right-0 text-grey absolute font-medium">
                    {children}
                </p>
            )}
        </>
    );
};

const CustomSlider = styled(Slider)(({ theme }) => ({
    "& .MuiSlider-mark": {
        backgroundColor: "#002F63",
        height: 9,
        width: 2,
    },
}));

const interestRate = 0.015;

export default function LoanCalculator({ customStyle }) {
    const [loanAmount, setLoanAmount] = useState(0);
    const [loanTerm, setLoanTerm] = useState(0);
    const [interestPerMonth, setInterestPerMonth] = useState(0);
    const [totalInterest, setTotalInterest] = useState(0);
    const [totalLoan, setTotalLoan] = useState(0);
    const [monthlyAmort, setMonthlyAmort] = useState(0);

    const handleLoanAmountChange = (event, newValue) => {
        setLoanAmount(newValue);
    };

    const handleTermChange = (event, newValue) => {
        setLoanTerm(newValue);
    };

    useEffect(() => {
        setInterestPerMonth(loanAmount * interestRate);
    }, [loanAmount]);

    useEffect(() => {
        setTotalInterest(loanTerm * interestPerMonth);
    }, [interestPerMonth, loanTerm]);

    useEffect(() => {
        setTotalLoan(loanAmount + totalInterest);
        setTotalLoan(loanAmount + totalInterest);
    }, [loanAmount, totalInterest]);

    useEffect(() => {
        setMonthlyAmort(totalLoan / loanTerm);
    }, [totalLoan, loanTerm]);

    return (
        <div className={`${customStyle}`}>
            <div className="h-11/12 w-full bg-white rounded-md mr-5 shadow-xl p-5 space-y-3 py-10 lg:w-9/12 lg:h-7/12 lg:w-7/12 md:shadow-none">
                <div className="mb-5">
                    <p className="text-left mb-9 text-grey font-medium text-lg">
                        Loan Amount
                    </p>
                    <CustomSlider
                        defaultValue={0}
                        name="loan-amount"
                        marks={loanAmountMarks}
                        valueLabelDisplay="on"
                        min={0}
                        max={2000000}
                        step={10000}
                        components={{
                            ValueLabel: ValueLabelComponent,
                            MarkLabel: MarkLabelComponent,
                        }}
                        sx={{
                            width: "98%",
                            color: rangeColor,
                        }}
                        onChange={handleLoanAmountChange}
                    />
                </div>

                <div className="mt-5 mb-16">
                    <p className="text-left mb-9 text-grey font-medium text-lg">
                        Term
                    </p>
                    <CustomSlider
                        name="term"
                        defaultValue={0}
                        min={0}
                        max={24}
                        step={1}
                        marks={termMarks}
                        components={{
                            ValueLabel: ValueLabelComponent,
                            MarkLabel: MarkLabelComponent,
                        }}
                        valueLabelDisplay="on"
                        sx={{
                            width: "98%",
                            color: rangeColor,
                        }}
                        onChange={handleTermChange}
                    />
                </div>

                <div className="space-y-3 my-10 text-black2 font-medium">
                    <div className="flex justify-between">
                        <p>Loan Amount:</p>
                        <p>{fCurrency(loanAmount)}</p>
                    </div>
                    <div className="flex justify-between">
                        <p>Term:</p>
                        <p>{loanTerm}</p>
                    </div>
                    <div className="flex justify-between">
                        <p>Monthly Amortization:</p>
                        <p>{fCurrency(monthlyAmort)}</p>
                    </div>
                </div>
                <LinkButton
                    title="Apply Now"
                    href="/application-form/ACES App Form.pdf"
                    color="bg-blue"
                    customStyle="rounded-3xl py-3 mt-10"
                    target="_blank"
                    external
                />
            </div>
        </div>
    );
}
