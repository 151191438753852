import Donna from "../../assets/our-leaders/Donna.png";
import Nathanael from "../../assets/our-leaders/Nathanael.png";
import Mikhael from "../../assets/our-leaders/Mikhael.png";
import Karen from "../../assets/our-leaders/Karen.png";
import Rafael from "../../assets/our-leaders/Rafael.png";
import Katheryne from "../../assets/our-leaders/Katheryne.png";
import Lara from "../../assets/our-leaders/Lara.png";

const ourLeaders = [
    {
        id: 1,
        fullName: "Donna Marcela R. Soriano",
        title: "Chairman",
        description: [
            "Donna is responsible for leading the board of directors and setting the governance standards of the company. She manages the leadership under the Management Committee and decides on all matters affecting operations and performance that are brought forward.",
            "Her years of business experience allows her to steer the company to achieve high-level organizational goals and objectives. Donna is an Accounting graduate from the National College of Business and Arts.",
        ],
        image: Donna,
    },
    {
        id: 2,
        fullName: "Nathanael Paolo R. Soriano",
        title: "President",
        description: [
            "Nate sets the overall strategic direction of the company. He is responsible for working with the Board of Directors and other executive officers to establish the long-term goals of the company, its strategies, plans and policies. He ensures that the strategies and plans are carried out in a timely manner.",
            "Prior to establishing and joining the company full time, he was a banker at Security Bank Corporation, handling responsibilities as Key Accounts Manager for the top 1000 corporations in the Philippines.",
            "Nate is a graduate of Financial Management from the University of Santo Tomas and obtained his Master in Business Administration (MBA) degree from the Ateneo de Manila University.",
        ],
        image: Nathanael,
    },
    {
        id: 3,
        fullName: "Mikhael Paolo R. Soriano",
        title: "Vice President - Sales",
        description: [
            "Mikko oversees the day-to-day sales performance of the business and is responsible for leading the team to meet the sales goal. He is responsible for sales forecasting, planning, as well as creating and executing sales strategies. He supervises sales operations to ensure a smooth working environment for the team. He works closely with the Marketing Department in terms of executing marketing strategies and market intelligence.",
            "Among the siblings, he has the longest period of involvement and experience in the lending industry. Before joining ACES, he worked in the business development department of S&S Metro Credit Inc. and obtained substantial experience that contributes to ACES' operations. Mikko graduated from San Beda University with a degree in Operations Management.",
        ],
        image: Mikhael,
    },
    {
        id: 4,
        fullName: "Karen Anne R. Soriano",
        title: "Vice President - Finance and Accounting",
        description: [
            "Karen is responsible for managing the financial resources of the company. She leads financial planning and analyzes the company's financial strengths and weaknesses, which allows her to propose corrective actions.",
            "As a certified public accountant, she is well-versed in providing and implementing controls that are necessary to sustain the growth of the business and to achieve its financial goals year after year.",
            "Karen worked with renowned accounting firm SyCip, Gorres and Velayo (SGV) prior to joining ACES full time. Her experience in SGV is fundamental in establishing the accounting and audit setup of the company.",
            "She graduated with a degree in Accountancy from the University of Santo Tomas and passed the CPA board examination in 2015.",
        ],
        image: Karen,
    },
    {
        id: 5,
        fullName: "Rafael Paolo R. Soriano",
        title: "Vice President - Credit and Risk Management",
        description: [
            "Rafael plays a critical role in managing risks by ensuring the creditworthiness of borrowers. He oversees the process of approval and granting of loans to borrowers. He works closely with the Sales Department to achieve target sales without compromising his department's ability to manage risks and control payment delinquencies to acceptable levels.",
            "He is six sigma-certified which allows him to identify process flaws and propose continuous improvement practices within his department and throughout the organization. His previous job at the Lhuillier Group proves valuable as he steers his department in operating efficiently and effectively.",
            "Rafael is the youngest among the Soriano siblings. He obtained his BS Industrial Engineering from the University of Santo Tomas.",
        ],
        image: Rafael,
    },
    {
        id: 6,
        fullName: "Katheryne Mae R. Soriano",
        title: "Vice President - Marketing",
        description: [
            "Kaye is responsible for the overall branding of ACES and the development of new products and services. This includes conceptualizing and implementing the promotional plans and strategies of the company that are aligned with the organization's mission. She spearheads the marketing campaigns of the company which involves identification of suitable products and services and launching these to the market.",
            "With marketing experience from one of the largest banks in the Philippines, Kaye's training at Banco de Oro allows her to fully utilize all available and practical marketing channels that will advance the goals and objectives of the company. She coordinates closely with the Sales Department to ensure that the company brand is promoted and embodied in all aspects of sales management.",
            "Kaye graduated from San Beda University and holds a degree in Financial Management.",
        ],
        image: Katheryne,
    },
    {
        id: 7,
        fullName: "Lara Porsche A. Soriano",
        title: "Vice President - Admin, People and Development",
        description: [
            "Lara oversees the planning, development, implementation and maintenance of the company's employee management and administrative services. Part of her responsibilities is ensuring that administrative support is provided to all departments and organizational policies are enforced. Her other responsibilities include managing administrative budgets, reviewing and negotiating vendor contracts, recruiting and training staff, developing internal policies and ensuring legal compliance.",
            "Prior to joining ACES, she worked for an electric power development company with focus on project development and legal compliance, where she gained significant understanding of the regulatory environment. She also concurrently served as a Data Protection Officer.",
            "Lara obtained her bachelor’s degree in Psychology from the University of the Philippines Diliman and her MBA from the Ateneo de Manila University.",
        ],
        image: Lara,
    },
    // {
    //     id: 8,
    //     fullName: "Ryan Yhannee G. Villosis",
    //     title: "Vice President - Business Development and Account Recovery",
    //     description: [
    //         "Yhannee plans and devises strategies to ensure that accounts with past due balances remain active. He is responsible for ensuring that collection and recovery efforts produce significant reductions in accounts receivable from borrowers.",
    //         "As head for business development, he spearheads exploring new business opportunities and branch expansion initiatives. In cooperation with other company officers, he helps establish policies, procedures and controls in the areas he handles.",
    //         "He is a seasoned sales person having been in the lending business for over 15 years. His candor, attitude and knack for sales and marketing earned him a good reputation amongst industry players. He worked overseas as a bank teller before coming home to help establish a lending company in 2013.",
    //         "Yhannee obtained his Business Management degree from Kabankalan Catholic College in Negros Occidental.",
    //     ],
    //     image: imageLeft,
    // },
];

export default ourLeaders;
