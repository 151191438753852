import "./App.css";

import StateProvider from "./context/StateContext";

import Routes from "./Route";

function App() {
    return (
        <StateProvider>
            <div className="App font-display text-black2">
                <Routes />
            </div>
        </StateProvider>
    );
}

export default App;
